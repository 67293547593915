@import '@scss/service';

$mobScale: 0.5;

.main {
  max-width: 800px;
  width: 100%;
  margin: 15.5em;
  text-align: center;

  @media screen and (max-width: 650px) {
    margin: 13em;
  }

  h2 {
    font-size: 2.25em;
    margin-top: 4em;

    @media screen and (max-width: 800px) {
      margin-top: 2.5em;
    }

    @media screen and (max-width: 650px) {
      margin-top: 1.5em;
      font-size: 1.5em;
    }
  }

  a {
    display: block;
    color: $blue;
    font-size: 1.5em;
    margin-top: 2em;
    font-weight: 600;

    &:hover {
      color: $blue-dark;
    }

    @media screen and (max-width: 650px) {
      margin-top: 1.2em;
      font-size: 1.1em;
    }
  }
}

.images {
  position: relative;
  padding-top: 32.5%;

  @media screen and (max-width: 800px) {
    transform: scale(0.8);
  }

  @media screen and (max-width: 650px) {
    transform: scale(0.7);
  }

  @media screen and (max-width: 560px) {
    transform: scale(0.5);
  }

  @media screen and (max-width: 400px) {
    transform: scale(0.4);
  }

  @media screen and (max-width: 320px) {
    transform: scale(0.3);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.7s linear;
  }

  .firstFour {
    transform: translate(-184%, -50%);
  }

  .triangle {
    transform: translate(-260%, -60%);
    z-index: 1;
  }

  .zero {
    width: 212px;
    height: 257px;
  }
  .topFourAngle {
    transform: translate(-897%, 162%);
  }

  .leftBottomFourAngle {
    transform: translate(-897%, -46%);
  }

  .horizontalWhite {
    transform: translate(-168%, 42%);
    z-index: 2;
    width: 208px;
    height: 58px;
  }

  .secondFour {
    transform: translate(72%, -62%);
  }

  .verticalWhiteFourAngle {
    transform: translate(386%, -15%);
    z-index: 1;
  }

  .topRightFourAngle {
    transform: translate(600%, 42%);
  }

  .ellipse {
    transform: translate(240%, -50%);
  }
}

/**** Start Point ****/

.imagesStartPoint {
  .firstFour {
    transform: translate(-250%, -160%);
  }

  .triangle {
    transform: translate(-385%, -60%);
  }

  .zero {
    width: 412px;
    height: 485px;
  }
  .topFourAngle {
    transform: translate(-500%, -860%);
  }

  .leftBottomFourAngle {
    transform: translate(-2000%, 700%);
  }

  .horizontalWhite {
    transform: translate(-150%, 770%);
    z-index: 2;
    width: 208px;
    height: 58px;
  }

  .secondFour {
    transform: translate(160%, -192%);
  }

  .verticalWhiteFourAngle {
    transform: translate(886%, -90%);
    z-index: 1;
  }

  .topRightFourAngle {
    transform: translate(2000%, 200%);
  }

  .ellipse {
    transform: translate(540%, 300%);
  }
}
